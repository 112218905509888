
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.letter {
  position: absolute;
  bottom: -10px;
  right: 10px;
  max-height: 90%;
  width: 22%;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    bottom: -30px;
    right: 80px;
  }
}
