
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.linkedin {
  .percentage {
    padding: 40px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    img {
      max-width: 100%;
    }
    .description {
      font-weight: 600;
      text-align: center;
      font-size: 18px;
      line-height: 130%;
    }
  }
  .firstEl {
    margin-bottom: 20px;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding-left: 32px;
      margin-bottom: 0;
    }
  }
  .secondEl {
    max-width: 370px;
    margin: auto;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding-right: 32px;
    }
  }
}
