
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.footer {
  background-color: #0c2a31;
  &.tmFooter {
    background: url('/images/footer.png');
    .footerWrapper {
      .menuItemsWrapper .menuItem {
        a,
        p {
          color: #000;
        }
        &.contactBtn {
          a {
            color: white;
          }
        }
      }
      .documents {
        color: #000;
        border-top: 1px solid #000;
        padding-top: 40px;
        a {
          color: #000;
        }
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  p {
    margin: 0;
  }
  .footerWrapper {
    padding: 70px 0;
    .menuItemsWrapper {
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        flex-direction: row;
      }
    }
    .menuItem {
      padding-right: 64px;
      a {
        color: #fff;
        &:hover {
          color: #e43e30;
        }
      }
      p {
        color: #fff;
        padding-bottom: 16px;
      }
      li {
        padding-bottom: 12px;
      }
      &.contactBtn {
        margin: 35px 0;
        width: 100%;
        a {
          color: white;
          padding: 10px 18px;
        }

        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          margin: 0;
          width: fit-content;
          padding: 0 10px;
        }
      }
    }
    .documents {
      flex-direction: column;
      color: #f2f4f7;
      border-top: 1px solid #f2f4f7;
      padding-top: 40px;
      a {
        padding-right: 10px;
        color: #f2f4f7;
      }
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        flex-direction: row;
        a {
          padding: 0 10px;
        }
      }
    }
  }
}
