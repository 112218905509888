
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.footer {
  position: relative;
  color: #fff;
  p {
    color: #fff;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: calc(500px + 450px);
    background: #0c2a31;
    opacity: 0.9;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      min-height: calc(500px + 350px);
    }

    // @media (min-width: map-get($grid-breakpoints, 'md')) {
    //   min-height: calc(500px + 380px);
    // }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      min-height: calc(500px + 450px);
    }
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      min-height: calc(500px + 500px);
    }
    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      min-height: calc(500px + 550px);
    }
  }
  .text {
    z-index: 9;
    position: absolute;
    top: 18%;
    width: 100%;

    .content {
      h2 {
        color: #fff;
        text-align: center;
        font-size: 35px;
        @media (min-width: map-get($grid-breakpoints, 'xxl')) {
          font-size: 65px;
        }
        img {
          width: 35px;
          vertical-align: sub;
          @media (min-width: map-get($grid-breakpoints, 'xxl')) {
            vertical-align: middle;
            width: 45px;
          }
        }
      }
    }
    .logos {
      padding: 20px 40px 20px 40px;
      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        display: flex;
        justify-content: space-between;
        padding: 20px 0 20px 0;
      }
      p {
        color: #fff;
        font-size: 10px;
        margin-bottom: 20px;
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          font-size: 22px;
        }
        @media (min-width: map-get($grid-breakpoints, 'xl')) {
          margin-bottom: 0;
        }
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      img {
        max-width: 40%;
      }
    }
  }
}
