
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.discoverBtn {
  &:hover {
    color: #53389e;
    border-color: #53389e;
    background-color: #e6e1fd;
  }
}
