
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.link {
  z-index: 3;
  svg {
    display: none;
    position: absolute;
    top: 50px;
    right: 50px;
    width: 40px;
    path {
      stroke: #fff;
    }
  }
  &:hover {
    svg {
      display: block;
    }
  }
}
