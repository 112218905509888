
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.blockquote {
  position: relative;
  padding-left: 60px !important;
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  border: none;
  display: inline-block;
  margin: 30px 0 !important;

  p {
    display: inline;
  }
  &:before {
    content: '“';
    font-size: 150px;
    position: absolute;
    color: var(--a3-color-0);
    top: 30px;
    left: 0;
  }
}
