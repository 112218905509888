@mixin flashCTA(
  $hoverBG: var(--ast-global-color-3),
  $flashColor1: rgba(255, 255, 255, 0.5),
  $flashColor2: rgba(255, 255, 255, 0.2)
) {
  position: relative;
  transition: 0.6s;
  overflow: hidden;

  &:focus {
    outline: 0;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: $flashColor1;
    width: 60px;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: $flashColor2;
    width: 30px;
    height: 100%;
    left: 30px;
    top: 0;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }
  &:hover {
    cursor: pointer;
    background-color: $hoverBG !important;
    &:before {
      transform: translateX(500px) skewX(-15deg);
      opacity: 0.6;
      transition: 0.7s;
    }
    &:after {
      transform: translateX(500px) skewX(-15deg);
      opacity: 1;
      transition: 0.7s;
    }
  }
}

%ButtonSVG {
  width: 12px;
  fill: #ffffff;
  position: relative;
  top: -1px;
  margin-left: 6px;
  transition: all 0.2s ease-in-out;
}

%cta {
  border-radius: 50px;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 15px 30px;
  text-decoration: none !important;
  background-color: #006b69;
  color: #ffffff !important;
  display: inline-block;
  text-align: left;
  font-weight: 900 !important;

  &:hover {
    color: #ffffff;
    text-decoration: none !important;
    background-color: #003d3c;
  }

  @include flashCTA(
    var(--ast-global-color-3),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.2)
  );

  svg {
    @extend %ButtonSVG;
  }
}

@mixin scrollbars(
  $size: 10px,
  $foreground-color: var(--ast-global-color-0),
  $background-color: var(--ast-global-color-1)
) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;
}
