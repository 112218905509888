
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.button {
  border-radius: 60px;
  display: inline-block;
  text-decoration: none;
  width: max-content;
  align-self: center;
  font-weight: 600;
  transition: 0.3s;

  &.large {
    font-size: 15px;
    padding: 12px 20px;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 20px;
      padding: 20px 50px;
    }
  }
  &.small {
    font-size: 13px;
    padding: 10px 18px;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding: 12px 22px;
      font-size: 16px;
    }
  }
}
