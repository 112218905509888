
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.banner {
  // overflow: hidden;
  padding-top: 70px;
  // min-height: 100vh;

  .bannerCont {
    // display: none;
    background: #eee;
    // height: 100vh;
    overflow: hidden;
    padding: 0;
    position: relative;
    padding-top: 56.25%;
    background-attachment: fixed;
    background-size: cover;

    > * {
      transition: all 0.3s linear;
    }

    div[class*='player'] {
      // .player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // }
    }
    .arrowWrapper {
      @media (min-width: '1400px') {
        position: absolute;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
      }

      .brandsArrow {
        position: absolute;
        left: 20px;
        bottom: 40px;
        z-index: 2;
        @media (min-width: '1400px') {
          left: 110px;
          bottom: 100px;
        }
      }
    }

    .textContent {
      position: absolute;
      top: calc(50% - 70px);
      left: 0;
      transform: translate(0, -50%);
      z-index: 99;
      width: 100%;
    }
    iframe {
      box-sizing: border-box;
      // height: 56.25vw;
      left: 50%;
      // min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      // width: 177.77777778vh !important;
      background-attachment: fixed;
    }
  }

  > div {
    // height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    position: relative;

    padding-bottom: 50px;

    background-size: auto 80vw;
    background-position: top right;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding-bottom: $navbar-height-minus-curve;
      background-size: cover;
      background-position: 100%;
      background-position: center;
    }

    .content {
      text-align: center;
      color: #ffffff;
      text-align: left;
      z-index: 1;
      position: relative;

      p {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.5px;
      }
    }
  }
}
