
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.banner {
  overflow: hidden;
  padding-top: 70px;

  h1 {
    font-size: 36px;
    line-height: 44px;
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      font-size: 60px;
      line-height: 72px;
    }
  }
  .banner-work {
    min-height: calc(85vh - 50px);
    height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    .copy {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 80px 0 80px;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      color: #ffffff;
      min-height: 100vh;
      * {
        color: #ffffff;
      }
    }

    iframe {
      position: relative;
      // margin-top: 60px;
      height: 702px;
      // margin-bottom: -60px;
    }
  }

  // margin-top: 70px;
  .bannerCont {
    background: #eee;
    height: 300px;

    // min-height: 500px;
    overflow: hidden;
    padding: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-attachment: fixed;
    // display: flex;
    // height: 800px;
    .textContent {
      // display: flex;
      // flex-direction: column;
      // align-self: center;
      // z-index: 99;
      // position: relative;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      z-index: 99;
      width: 100%;
    }
    .inner {
      min-height: 43.75%;
      padding-top: 56.25%;
      position: absolute;
      top: 0;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      > * {
        // transform: translate(-50%, -50%);
        margin-top: -100%;
      }
      height: 100%;
      // width: 122%;
    }
    iframe {
      // bottom: 0;
      // height: 100%;
      // left: 0;
      // position: absolute;
      // right: 0;
      // top: 0;
      // width: 100%;
      box-sizing: border-box;
      height: 56.25vw;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      width: 177.77777778vh !important;
      background-attachment: fixed;
    }
  }

  > div {
    // display: flex;
    // align-items: center;
    overflow: hidden;
    background-repeat: no-repeat;
    position: relative;

    padding-bottom: 50px;

    background-size: auto 80vw;
    background-position: top right;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding-bottom: $navbar-height-minus-curve;
      background-size: cover;
      background-position: 100%;
    }

    .content {
      text-align: center;
      color: #ffffff;
      text-align: left;
      z-index: 1;
      position: relative;

      p {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.5px;
      }
    }
  }

  .banner-full {
    min-height: calc(100vh);

    .copy {
      text-align: left;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .image {
      width: 50%;
      height: auto;
      margin: 0 auto;
      margin-bottom: 20px;

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        margin-left: initial;
        margin-left: initial;
      }
    }
  }

  // .banner-standard {
  //   min-height: 600px;
  // }
}

// .video {
//   position: relative;
//   overflow: hidden;
//   width: 100%;
//   height: 100vh;
//   .react-player {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     max-width: 100vw;
//     max-height: 56.25vw;
//   }
// @media (max-aspect-ratio: 16/9) {
//   height: 100vh;
//   width: calc(100vh * 16 / 9);
// }
// }

.form {
  background: #ecf0f9;
  border-radius: 20px;
  padding: 30px;
  text-align: left;
  color: #000000;

  div[class*='HSForm_hsform'] {
    background-image: none !important;
    label {
      width: 100%;
      text-align: left;
    }

    div[class*='legal-consent-container'] {
      margin-top: 20px;
    }
  }

  // @extend %hbspt-form;
  // .hs_submit .actions {
  //   text-align: center;
  // }
}
