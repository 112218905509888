
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.navBar {
  position: absolute;
  top: 0;
  height: 80px;
  width: 100%;
  z-index: 99;
  align-items: center;
  margin: 10px 0;

  // .navWrapper {
  // display: flex;
  // width: 100%;
  // height: 100%;

  .links {
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    background-blend-mode: lighten;

    backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      justify-content: center;
    }
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding: 0 50px;
      justify-content: flex-start;
    }
    .hamburger {
      display: block;
      width: 50px;
      height: 50px;
      cursor: pointer;
      .bar {
        width: 40px;
        height: 3px;
        background-color: #ecf0f1;
        display: block;
        margin: 8px auto;
        transition: all 0.3s ease-in-out;
      }
      .open.bar:nth-child(2) {
        opacity: 0;
      }
      .open.bar:nth-child(1) {
        transform: translateY(11px) rotate(45deg);
      }
      .open.bar:nth-child(3) {
        transform: translateY(-11px) rotate(-45deg);
      }
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        display: none;
      }
    }
    .mobileMenu {
      display: none;
      a {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      &.open {
        display: block;
        position: absolute;
        top: 100%;
        padding-top: 50px;
        background: #000000ea;
        width: calc(100% + 30px);
        height: 100vh;
        border-radius: 16px;
        left: -13px;

        a {
          width: 100%;
          display: inline-block;
          margin: 15px 0;
          padding: 20px 0;
          text-align: center;
          font-size: 30px;
          &:hover {
            background: #fffefe37;
          }
        }
        .cta {
          max-width: 200px;
          display: block !important;
          margin: 0 auto;
          a {
            font-size: 20px !important;
          }
          @media (min-width: map-get($grid-breakpoints, 'lg')) {
            display: none !important;
          }
        }
      }
      .cta {
        display: block;
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          display: none;
        }
      }
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        display: flex;
      }
    }
  }
}
