
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.locations {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    border-left: 4px solid #f2f4f7;
    padding: 20px;
    transition: all 300ms;
    p {
      color: #0c2a31;
    }
    &.active {
      border-color: #000000;
    }
    .visit {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.map {
  height: 500px;
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    height: 100%;
  }
}
