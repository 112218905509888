
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.videoWrapper {
  position: relative;
  padding-top: 56.25%;
  margin-top: 70px;
  .player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &.coverHidden {
      display: none;
    }
  }
}
