// Bootstrap grid
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 920px,
  xl: 1120px,
  xxl: 1280px,
);
// $grid-gutter-width: 32px;

// Agent3
$navbar-height: 142px;
$navbar-height-minus-curve: 100px;

$navbar-height-mobile: 100px;

//Colors
$primary: #1473e6;
$primary2: #0d66d0;

$secondary: #000000;
$secondary2: #444444;

$black: #000000;
$black-alt: #2c2c2c;
$white: #ffffff;
$orange: #f99d1c;
$pink: $primary;
$grey: #656c76;
$grey1: #f0f3f7;
$statsSectionBackground: #363c44;
$performanceMaturity: #f0f3f7;
$buttonHover: #e8339b;
$red: #eb1000;

$titles-color: #1473e6;
$text-color: #000000;
$text-color-white: #ffffff;
$blue: #1573e6;
$blue-alt: #0d66d0;
$blue-bg: #f2f7fa;
$transparent: #0d65d000;
