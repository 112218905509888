
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.specialWrapper {
  height: 500px;

  .box {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    p,
    h6,
    a {
      color: #ffffff;
    }

    h6 {
      // padding-bottom: 2px;
    }
    p {
      padding-bottom: 12px;
    }
    p[class*='hidden'] {
      padding: 5px 0 5px;
      box-sizing: border-box;
    }

    p:last-child {
      padding-top: 6px;
      padding-bottom: 0;
    }

    .copy {
      position: absolute;
      bottom: 0;
      padding: 40px;
      z-index: 0;
      font-weight: 700;
      div[class*='width-50'] {
        width: 100%;
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          width: 50%;
        }
      }
    }

    p[class*='hidden'] {
      max-height: 0;
      position: relative;
      // top: 18px;
      overflow: hidden;
      transition: max-height 0.3s linear, padding 0.1s linear 0.2s;
    }

    &:hover {
      &.oYauto {
        p[class*='hidden'] {
          // padding-top: 6px;
          overflow-y: auto;
        }
      }
      p[class*='hidden'] {
        padding-top: 12px;
        // overflow-y: auto;
        // max-height: 230px;
        transition: padding 0.1s linear, max-height 0.3s linear;
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          // max-height: 400px;
        }
      }
    }
  }
}
