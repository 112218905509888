
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.navbar {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 9999;
  .menu {
    padding: 20px 40px;
    color: #fff;
    background-color: #93939372;
    border-radius: 16px;

    .logoWrapper {
      width: 200px;
      display: block;
      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
    .menuToggler {
      cursor: pointer;
      position: absolute;
      width: 1.5em;
      height: 1.5em;
      margin-left: auto;
      right: 20px;
      top: 25px;

      .line {
        display: block;
        position: absolute;
        height: 1px;
        width: 25px;
        background: #fff;
        border-radius: 9px;
        opacity: 1;
        right: 0;
        transition: all 0.3s ease-in-out;
        font-size: 0;
        top: 18px;

        &:nth-child(1) {
          top: 6px;
          right: 0;
        }

        &:nth-child(2) {
          top: 12px;
          right: 0;
        }
      }

      &.close {
        .line {
          background: #fff;
          &:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
            height: 1px;
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
            height: 1px;
          }
        }
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      display: none;

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        display: block;
      }
      &.open {
        display: block;
        margin-top: 30px;
        padding-left: 0;
        background-color: #02575c;
        padding: 10px 0;
        border-radius: 16px;
        li {
          display: block;
          text-align: center;
          font-size: 25px;
          font-weight: 700;
        }
        .buttonMobile {
          margin: 10px auto;
          display: block;
        }
      }
      li {
        margin-bottom: 15px;
        display: inline-block;
        padding: 5px 10px;
        margin: 0;
        @media (min-width: map-get($grid-breakpoints, 'xl')) {
          padding: 5px 25px;
        }

        a {
          color: #fff;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.hideMenu {
  .navbar {
    top: 0;
    transition: all 0.3s linear;

    @media (max-width: map-get($grid-breakpoints, 'xl')) {
      & > div {
        padding-right: 0rem !important;
        padding-left: 0rem !important;
      }
    }
  }
  .menu {
    background-color: #02575c;
    @media (max-width: map-get($grid-breakpoints, 'xl')) {
      border-radius: 0;
    }
  }
}
