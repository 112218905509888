
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.googleEmbed {
  // width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  height: 800px;
  margin-top: 30px;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
