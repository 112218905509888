
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.footer {
  padding: 50px 0;
  background: linear-gradient(
    270deg,
    rgba(44, 142, 142, 1) 0%,
    rgba(2, 87, 92, 1) 91%
  );
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    padding: 100px 0;
  }
  .logoWrapper {
    width: 250px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .linkWrapper {
    position: relative;

    margin: 30px 0;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      margin: 0;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      min-height: 67px;

      max-width: 600px;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      li {
        margin-bottom: 15px;
        a {
          color: #fff;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .btnWrapper {
    display: inline-block;
    margin-top: 30px;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0px;
    }
  }
  .copyrightFooter {
    border-top: 1px solid #fff;
    padding-top: 30px;
    a {
      padding-top: 20px;
      display: inline-block;
      margin-right: 15px;
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        margin-left: 15px;
        padding-top: 0;
      }
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
