.btn-primary {
  // @include button-variant($primary, $primary, $white, $primary2, $primary2, $white);
  //$btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius

  @include button-variant(#ffffff, #d0d5dd, #344054, $primary2, $primary2, $white);

  @include button-size(16px, 28px, 18px, 8px);
  font-weight: 500;

  :hover {
    text-decoration: none;
  }
  svg {
    margin-right: 10px;
    margin-top: -4px;
    g,
    path {
      fill: #344054 !important;
    }
  }
}

.btn-secondary {
  @include button-variant($secondary, $secondary, $white, $secondary2, $secondary2, $white);
  //$btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius
  @include button-size(3px, 14px, 14px, 16px);
  font-weight: 700;
}

.btn-blue,
a.btn-blue:link,
a.btn-blue:focus,
a.btn-blue:visited,
a.btn-blue:hover,
a.btn-blue:active {
  @include button-variant($blue, $blue, darken($blue, 10%), darken($blue, 10%), lighten($blue, 10%), $white);
  @include button-size(0.5rem, 1.3rem, 0.9rem, 3rem);
  &.slimsBtn {
    @include button-size(0.24rem 1.2rem 0.34rem, 1.2rem, 1.1rem, 3rem);
    box-shadow: none !important;
  }
  color: $white;
  text-decoration: none;
  padding-top: 0.5rem;
  font-size: 1.2rem;
  font-family: "AdobeClean-Bold", Helvetica, Arial, sans-serif;
  &.disabled {
    background-color: #eeeeee !important;
    border-color: #eeeeee !important;
    color: #c3c3c3 !important;
  }
}

.btn-outlined {
  @include button-variant(
    $transparent,
    $text-color,
    darken($text-color, 10%),
    darken($text-color, 10%),
    lighten($text-color, 10%),
    lighten($text-color, 10%)
  );
  @include button-size(0.3rem, 1.5rem, 1rem, 3rem);
  &.slimsBtn {
    @include button-size(0.2rem 2.2rem 0.25rem, 2.2rem, 1.1rem, 3rem);
    box-shadow: none !important;
  }
  border-radius: 100px;
  text-decoration: none !important;
  color: $text-color;
  border: 2px solid $text-color;
  font-family: "AdobeClean-Bold", Helvetica, Arial, sans-serif;

  &:hover,
  :active,
  :focus,
  :visited,
  :focus-within,
  :focus-visible {
    background: $text-color;
    border-color: $text-color;
    box-shadow: none;
    color: $text-color-white;
  }
}

.btn-outlined-white {
  color: $white;
  border-color: $white;
  &:hover,
  :active,
  :focus,
  :visited,
  :focus-within,
  :focus-visible {
    background: $white;
    border-color: $white;
    box-shadow: none;
    color: $text-color;
  }
}
