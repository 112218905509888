
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.banner {
  padding-top: 70px;

  .bannerCont {
    background: #eee;
    overflow: hidden;
    padding: 0;
    position: relative;
    padding-top: 567px;
    background-attachment: fixed;
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    &.solutionsBanner {
      background-size: contain;
      .textContent .content {
        a {
          padding: 10px 18px;
          font-size: 16px;
        }
      }
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        background-size: cover;
        .textContent {
          top: calc(50% - 20px);
          .content {
            width: 70%;
          }
        }
      }
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      background-size: cover;
      background-position: center center;
    }

    > * {
      transition: all 0.3s linear;
    }

    div[class*='player'] {
      // .player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // }
    }

    .textContent {
      position: absolute;
      top: calc(50% - 70px);
      left: 0;
      transform: translate(0, -50%);
      z-index: 99;
      width: 100%;

      h1 {
        font-style: normal;
        font-weight: 800;

        font-size: 34px;
        line-height: 44px;
        @media (max-width: map-get($grid-breakpoints, 'md')) {
          width: 100% !important;
        }

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 86px;
          line-height: 100px;
        }
      }
    }
    iframe {
      box-sizing: border-box;
      // height: 56.25vw;
      left: 50%;
      // min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      // width: 177.77777778vh !important;
      background-attachment: fixed;
    }
  }

  > div {
    // height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    position: relative;

    padding-bottom: 50px;

    background-size: auto 80vw;
    background-position: top right;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding-bottom: $navbar-height-minus-curve;
      background-size: cover;
      background-position: 100%;
      background-position: center;
    }

    .content {
      text-align: center;
      color: #ffffff;
      text-align: left;
      z-index: 1;
      position: relative;

      p {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.5px;
      }
    }
  }
}
