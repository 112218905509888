
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.bannerSimple {
  min-height: 80vh;
  padding: 70px 0;
  display: flex;
  align-items: center;
  background-size: cover;
  h1 {
    font-size: 36px;
    line-height: 44px;
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      font-size: 60px;
      line-height: 72px;
    }
  }
}
