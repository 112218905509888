
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.navbar {
  height: 70px;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  // transition: all 0.5s;
  display: flex;
  align-items: center;
  z-index: 99;
  a {
    color: #000;
    text-decoration: none;
  }

  .menuToggler {
    cursor: pointer;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    margin-left: auto;

    .line {
      display: block;
      position: absolute;
      height: 1px;
      width: 25px;
      background: #344054;
      border-radius: 9px;
      opacity: 1;
      right: 0;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0 !important;
      font-size: 0;
      top: 18px;

      &:nth-child(1) {
        top: 6px;
        right: 0;
      }

      &:nth-child(2) {
        top: 12px;
        right: 0;
      }
    }

    &.close {
      background-image: none;

      .line {
        background: #667085;
        &:nth-child(1) {
          transform: translateY(6px) rotate(45deg);
          height: 1px;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateY(-6px) rotate(-45deg);
          height: 1px;
        }
      }
    }
  }

  .menuWrapper {
    width: 100%;
    height: 100%;
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      position: absolute;
      top: 100%;
      left: 0;
      height: 0;
      // transition: height 0.5s linear;
      background: #ffffff;
      overflow: hidden;
    }
    &.open {
      height: 100vh;
      background: #ffffff;
    }

    .navMenu {
      display: flex;
      flex-direction: column;
      padding: 20px;
      width: 100%;
      list-style: none;
      height: 100%;
      margin: 0;

      li {
        > * {
          font-size: 16px;
          line-height: 24px;
          color: #0c2a31;
          font-weight: 500;
          @media (min-width: map-get($grid-breakpoints, 'lg')) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      a:hover {
        color: #53389e;
      }
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        flex-direction: row;
        align-items: center;
        padding: 0;
      }

      .menuItem {
        // margin-bottom: 20px;
        line-height: 48px;

        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          padding: 0 20px;
          display: flex;
          height: 100%;
          align-items: center;
        }
      }
      .dropdownItem {
        p {
          cursor: default;
        }
        a,
        p {
          &.dropdownTrigger {
            display: flex;
            align-items: center;

            .arrow {
              display: inline-block;
              margin-left: 5px;
              transition: transform 0.3s ease-in-out;
              font-size: 10px;
              position: relative;
              // background-color: red;
              padding: 12px;
              pointer-events: none;
              z-index: 999999;

              svg {
                path {
                  stroke: #667085;
                }
              }

              @media (min-width: map-get($grid-breakpoints, 'lg')) {
                display: none;
              }
            }
            &:hover {
              color: #53389e;
            }
          }
        }
        .dropdown {
          padding-right: 40px;
          top: 0;
          position: relative;
          width: 100%;
          background-color: #fff;
          max-height: 0;
          list-style: none;
          transition: height 0.8s;
          @media (min-width: map-get($grid-breakpoints, 'lg')) {
            margin-top: -12px;
            margin-left: -20px;
            border-radius: 0 0 16px 16px;
            box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
              0px 12px 16px -4px rgba(16, 24, 40, 0.08);
          }
          li {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.8s;

            a {
              // padding-left: 20px;

              color: #0c2a31;
              display: block;
              height: 100%;

              font-size: 14px;
              line-height: 20px;
              color: #0c2a31;
              font-weight: 500;

              span {
                color: #898a92;
                font-weight: 400;
                margin-top: 4px;
                display: block;
              }

              &:hover {
                color: #53389e;
              }
            }
          }
          @media (min-width: map-get($grid-breakpoints, 'lg')) {
            position: absolute;
            top: 100%;
            width: 400px;
          }
        }

        // Mobile
        @media (max-width: map-get($grid-breakpoints, 'lg')) {
          &.openedDropdown {
            .dropdown {
              max-height: 100vh;
              padding: 0 12px;

              li {
                padding: 12px;
                visibility: visible;
                opacity: 1;
              }
              &:after {
                content: '';
                display: block;
                height: 1px;
                width: 200%;
                background: #eaecf0;
                position: absolute;
                left: -50%;
              }
            }

            .arrow {
              transform: rotate(180deg);
              svg {
                path {
                  stroke: #53389e;
                }
              }
            }
          }
        }

        // Desktop hover
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          &:hover {
            .dropdown {
              width: 300px;
              position: absolute;
              max-height: 100vh;
              padding: 12px;

              li {
                padding: 12px;
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }

      .contactBtn {
        border: none;
        margin: 20px 0;
        a {
          font-weight: 800;
          color: #fff;
          padding: 10px 18px;
        }

        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          // margin-top: 0;
          margin-left: auto;
          margin-right: 0;
        }
      }

      .docs {
        a {
          color: #667085;
          margin-bottom: 10px;
          width: 50%;
          display: inline-block;
        }
      }
    }
  }
}
