
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.results {
  p {
    font-size: 20px;
  }
  .value {
    font-size: 50px;
    font-weight: 500;
    width: fit-content;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      font-size: 80px;
    }
  }
  .description {
    line-height: 24px;
  }
  .capability {
    background-color: #f2f4f7;
    font-size: 14px;
    border-radius: 16px;
  }
}
