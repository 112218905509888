
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.formWrapper {
  .title {
    background-image: url('/images/hsbg.png');
    background-size: cover;
    padding: 35px;
    color: #fff;
    border-radius: 16px 16px 0 0;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding: 64px;
      border-radius: 16px 0 0 16px;
      width: 40%;
    }
  }
  .form {
    border-radius: 0px 0px 16px 16px;
    border: 2px solid #5c47c1;
    padding: 50px 35px;
    flex: 1;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      border-radius: 0px 16px 16px 0px;
      padding: 64px;
      border-left: none;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    input,
    select,
    textarea {
      height: 40px;
      padding: 10px 14px;
      width: 100% !important;
      margin: 10px 0;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      font-size: 14px;
      line-height: 24px;
    }
    input[type='submit'] {
      margin-top: 30px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 800;
      height: auto;
      width: auto !important;
      color: #fff;
      padding: 10px 18px;
      border: none;
      border-radius: 60px;
      background-color: var(--a3-color-0);
      color: #ffffff;
      &:hover,
      &.hover {
        background-color: var(--a3-color-hover-0) !important;
        color: #ffffff;
      }
    }

    textarea {
      height: 120px;
    }
    *[class*='hs-error-msgs'] {
      color: #f2545b;
      list-style: none;
      padding: 0;
      margin: 0;
    }
    *[class*='hs-form-field'] label span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
    *[class*='legal-consent-container'] p {
      font-size: 12px;
    }
    *[class*='hs-form-booleancheckbox'] label {
      margin-top: 10px;
      display: flex;
      align-items: center;
      input[type='checkbox'] {
        width: auto !important;
        height: auto;
        margin: 0;
      }
    }
  }
}
