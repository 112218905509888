
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.A3Player {
  h1 {
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
    @media all and (min-width: 576px) {
      font-size: 51px;
      line-height: 54px;
    }
  }
  p {
    font-size: 20px;
    line-height: 25px;
    margin: 20px 0;
  }

  img {
    width: 100%;
    cursor: pointer;
  }
  .wrapper {
    .videoWrapper {
      padding-bottom: 56.25%;
      position: relative;
      border-radius: 16px;
      background-size: cover;

      .action {
        position: absolute;
        top: calc(50% - 61px);
        left: calc(50% - 61px);
        cursor: pointer;
        z-index: 9999;
      }
      iframe,
      video,
      .reactPlayer {
        aspect-ratio: 16 / 9;
        width: 100%;
        border-radius: 16px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffff;
      }
    }
    .description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .linksWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 190px;
        .copyIcon {
          margin-right: 5px;
        }
        .linkedin {
          path {
            fill: #0c2a31;
          }
        }
      }

      .videoDescription {
        background-color: #eeeeeecc;
        padding: 25px;
        border-radius: 16px;
        p {
          font-size: 18px;
          line-height: 25px;
          margin: 0;
        }
      }
    }
  }
  .listWrapper {
    border: 1px solid #0c2a3180;
    border-radius: 16px;
    padding-bottom: 7px;

    h6 {
      padding: 20px;
      font-size: 20px;
      line-height: 20px;
      border-bottom: 1px solid #0c2a3180;
    }
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #5d47c2;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #5434df;
    }
    .videoList {
      overflow-y: auto;
      overflow-x: hidden;

      .activePoster {
        background-color: #5d47c226;
      }
      .item {
        display: flex;
        gap: 26px;
        padding: 20px;

        h4 {
          font-weight: 900;
          font-size: 18px;
          line-height: 18px;
        }

        cursor: pointer;
        img {
          width: 175px;
          border-radius: 6px;
          align-self: center;
        }
      }
    }
  }
}
