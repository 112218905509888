
          @import "src/styles/variables.scss";
          @import "src/styles/functions.scss";
          @import "src/styles/placeholders.scss";
          @import "src/styles/bootstrap/config-bootstrap.scss";
          
        
.mainVideoWrapper {
  p {
    color: #000000;
    &:nth-child(n + 2) {
      color: #101828;
      font-weight: 500;
    }
  }
  .playerWrapper {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    border-radius: 16px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .featuring {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      flex-direction: row;
      justify-content: flex-end;
    }
    p {
      color: #fff;
      margin-bottom: 12px;
    }
    .copyIcon {
      margin-right: 5px;
      path {
        stroke: #fff;
      }
    }
  }
}
